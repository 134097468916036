<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <b-link class="brand-logo">
                <img
                    src="@/assets/images/logo/logo.png"
                    alt="Logo"
                    class="logo-auth"
                />
                <h2 class="brand-text text-primary ml-1">Leechall.io</h2>
            </b-link>
            <!-- Verify Email -->
            <b-card class="mb-0 text-center">
                <b-card-text class="mb-2">
                    <b-spinner label="Loading..." v-if="loading" />
                    <b-avatar variant="success" v-if="success">
                        <feather-icon icon="CheckIcon" />
                    </b-avatar>
                    <b-avatar variant="light-warning" v-if="warning" size="lg">
                        <feather-icon icon="AlertTriangleIcon" />
                    </b-avatar>
                </b-card-text>
                <b-card-title class="mb-2">{{ status }}</b-card-title>
            </b-card>
            <!-- /Verify Email -->
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BButton,
    BSpinner,
    BAvatar,
} from "bootstrap-vue";
import axios from "axios";

export default {
    components: {
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BButton,
        BSpinner,
        BAvatar,
    },
    data() {
        return {
            status: "Verifying your email...",
            loading: true,
            success: false,
            warning: false,
        };
    },
    async created() {
        try {
            const response = await axios.get(
                "auth/email/verify/" +
                    this.$route.params.id +
                    "/" +
                    this.$route.params.hash +
                    window.location.search
            );
            if (response.data.success) {
                this.loading = false;
                this.success = true;
                this.status = response.data.message;
                setTimeout(() => (window.location.href = "/"), 2000);
            }
        } catch (error) {
            this.loading = false;
            this.success = false;
            this.warning = true;
            this.status = "Email verification failed.";
            setTimeout(() => this.$router.push({ name: "auth-verify" }), 2000);
        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
